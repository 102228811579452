html {
  display: grid;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  overscroll-behavior: none;
  transition: background-color 1s;
  color: #253745;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin-bottom: 10px;
  color: #4a505d;
}

ul {
  color: #4a505d;
}

div {
  line-height: 140%;
}